import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core'

@Component({
  selector: 'rmd-logo',
  templateUrl: './rmd-logo.component.html',
  styleUrls: ['./rmd-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdLogoComponent {
  @Input() variant: 'light' | 'dark' = 'light'
  @Output() logoClicked = new EventEmitter<void>()
  @HostBinding('class.rmd-logo') private readonly cssClass = true
}
