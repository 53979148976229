import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BuiIconModule } from '@fleet-customer/booster-ui'
import { RmdLogoComponent } from './rmd-logo.component'

@NgModule({
  declarations: [RmdLogoComponent],
  imports: [CommonModule, BuiIconModule],
  exports: [RmdLogoComponent],
})
export class RmdLogoModule {}
